import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/AurumHeroContent.js";
import Features from "components/features/ThreeColWithTopImage.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
import FAQ from "components/faqs/SingleCol.js";
// import GetStarted from "components/cta/DownloadApp.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
import VideoContentImage from "images/aurum_Icons/aurum-video-library.svg";
import ContentSecurityImage from "images/aurum_Icons/aurum-contentSecurity.svg";
import DevicesImage from "images/aurum_Icons/aurum-devicesImage.svg";
import caseStudyLogoImage from "images/clientLogos/iamsnext.svg";
import caseStudyProfileImage from "images/drsukrit.png"
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import DesignIllustration from "images/aurum_Icons/heroImg.svg";
import { components } from "ComponentRenderer.js";
import { Helmet } from 'react-helmet';
export default ({
  landingPages = components.landingPages,
  innerPages = components.innerPages,
  }) => {
  const Subheading = tw.p`tracking-widest text-textBlack text-center`;
  const HighlightedText = tw.span`text-primary-500`;
  const VerticalSeprator = tw.div`mt-20 xs:mt-0`;

  return (
    <>
      <Helmet
      
      title='Video Streaming Services | Aurum Labs'
      
      meta={[
        {
          name: 'description',
          content: 'Live Video Streaming, Recorded Video Streaming, Video Hosting - Platform, API and Player',
        },
        {
          property: `og:title`,
          content: 'Video Streaming Services | Aurum Labs',
        },
        {
          property: `og:description`,
          content: 'Live Video Streaming, Recorded Video Streaming, Video Hosting - Platform, API and Player',
        }]}
    />
      <Hero roundedHeaderButton={true} 
        mainTitle="Deliver Amazing Video Streaming Experiences that Wows" 
        subTitle="Stream Live & Video on Demand (Recorded) to any Size Audience, on almost any device, Online or Offline. "
        buttonText="Lets Stream"
        buttonUrl="https://wa.me/918826622806/?text=Hi, We would like to stream videos with Aurum"
        imageSrc={VideoContentImage}
        showClient= {true}
      />
      <AnimationRevealPage>
        
        <VerticalSeprator className="v-seperator" />
        <CaseStudy
          imageSrc={caseStudyLogoImage}
          testimonialText = '“We knew live streaming of our lectures would be an exerience that every doctor preparing for NEET would enjoy, coupled with offering lectures online and offline seamlessly has to be done the right way. That&apos;s why we chose to build with Aurum.”'
          profileImageSrc={caseStudyProfileImage}
          customerName="Dr. Sukrit Sharma"
          customerTitle="Founder, CEO, IAMS Next"
          buttonUrl= ""
        />
        <MainFeature
          primaryButtonText="Learn More"
          primaryButtonUrl = {innerPages.LiveVideoStreaming.url}
          heading="Impress Your Viewers with beautiful on-brand Live Streams"
          description="<ul><li>Produce professional, reliable streams easily with standard broadcasters like OBS and Xsplit, simple configuration.</li><li>Make the video your own by adding logos, banners, overlays and more.</li><li>Deliver stunning videos in HD resolution, Fully Encrypted and Secured.</li><li>Get valuable insights on your videos with Real Time Analytics & Usage Data.</li><li>Engage Users publicly and privately with Interactive Chat.</li><li>Add Live Session to Content Library with Aurum Video Encryption and Storing Capabilities.</li><li>Simple REST APIs to build custom apps or integrations of Live Streams.</li></ul>"
          subheading="Deliver to Any Size Audience, Almost On Any Device"
          textOnLeft={false}
          imageSrc={StrongBrandImage}
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        <MainFeature
          primaryButtonText="Learn More"
          primaryButtonUrl = {innerPages.VideoStreaming.url}
          heading="Make the most out of Recorded Videos (Videos on Demand)"
          description="<ul><li>Extend your audience reach by streaming pre recorded videos through Apps and APIs.</li><li>Maintain One Common Source of Encrypted Video Files.</li><li>Easily add Live Sessions and Other Videos to the Library.</li><li>Create Curated Videos, Monetise them dynamically.</li><li>Get valuable insights on your videos with Usage Data.</li><li>Simple REST APIs to build custom apps or integrations of recorded videos.</li></ul>"
          subheading=""
          imageSrc={VideoContentImage}
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        <MainFeature
          primaryButtonText=""
          primaryButtonUrl = ""
          heading="Provide a fully immersive experience for your users without internet (Offline Delivery)"
          description="<ul><li>Grow your business with best-in-class capabilities to deliver Offline Content.</li><li>Deliver your fully encrypted videos & PDFs through Google Drive / Pen Drive or Public URLs Downloads.</li><li>Eliminate the stress, delays and errors in maintaining multiple content formats for online and offline delivery.</li><li>Manage Content Access by dynamic user access policy and control.</li><li>Screen Capture disabled in Windows desktops and laptops, Android & iOS mobile phones.</li><li>Engage offline users with live sessions on same platform.</li></ul>"
          subheading="No Hassles, Smart Platform."
          textOnLeft={false}
          imageSrc={ContentSecurityImage}
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        
        <FAQ
          subheading={<Subheading></Subheading>}
          description={'Video Streaming - Devices, Flexibility & Security'}
          heading={
            <>
              Frequently Asked Questions
            </>
          }
          faqs={[
            {
              question: "What is live video streaming ?",
              answer:
                "Live video streaming is the broadcasting of audiovisual content over the internet in real time."
            },
            {
              question: "What is the difference between live streaming and video on demand?",
              answer:
                "Video streaming can take the form of both live and recorded content. With live streaming, the content plays as it’s being captured. Video on demand (VOD), on the other hand, describes prerecorded content that internet-connected users can stream by request."
            },
            
            {
              question: "How are videos delivered offline i.e. without Internet ?",
              answer:
                "Encrypted videos are shared with Users through Pen Drive, Google Drive or Through Downloadable Links. The files are playable only in Aurum Desktop Apps with Complete Content Security and Digital Rights Management (DRM). In Mobile Apps, Users can download the file Phone Memory and Access the files with Complete DRM."
            },
            {
              question: "What is the impact of Video Quality on User Experience ?",
              answer:
                "Higher the Quality of the Video i.e. SD, HD or FULL HD, higher will be file size per hour. So if file size of 1 Hr of Video is 500 MB or 1 GB, it directly impacts the <b>Speed of the Broadband / Internet Required</b> by User other wise video will buffer."
            },
            {
              question: "What is Adaptive Bit Rate Streaming ?",
              answer:
                "Adaptive bitrate streaming or ABR streaming, sometimes shortened to ABS, is a technique for dynamically adjusting the compression level and video quality of a stream to match bandwidth availability at the user end.<p>The Content creators should keep it in mind that ABR Streaming adds to the streaming costs for both Live and Recorded Video Streaming.</p><p>This is also referred to as Video Transrating.</p><p>Aurum enables Adaptive Bitrate Streaming.</p>"
            }
          ]}
        />
        <PreFooter formheading="Streaming Videos Live and VOD Amplifies Your Business & Growth" formsubheading="Book Our Free Discussion Session Today - <b>How to Start Streaming and Grow Video Business</b>"/>
      </AnimationRevealPage>
      <Footer />
   </>
  );
}

import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings";
import {SectionDescription} from "components/misc/Typography";
import { ReactComponent as TwitterIcon} from "images/twitter-icon.svg";
import { ReactComponent as LinkedinIcon} from "images/linkedin-icon.svg";
import { ReactComponent as GithubIcon } from "images/github-icon.svg";
import defaultCardImage from "images/shield-icon.svg";


const Cards = styled.div`
  &{
    @media only screen and (min-width:640px){
      animation: 35s linear 0s infinite normal none running scrollUp;
      transform: translateY(0);
      transform-style: preserve-3d;
      backface-visibility: hidden;  
    }
    &:hover {
      animation-play-state: paused;
    }
  }
  ${tw`flex flex-wrap flex-row justify-center w-full lg:absolute md:top-0 overflow-hidden`}
`
const Card = tw.div`m-3 bg-white pr-0 pt-3 pb-4 rounded-xl w-full sm:w-1/2 lg:w-1/4 flex flex-col items-center`

const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-0 text-center`}
  .subText {
    line-height: 1.72em;
    font-size:14px;
    max-width:90%;
    ${tw`text-gray-600`}
  }
  .name {
    ${tw`mt-4 mb-4 text-base font-bold text-gray-900`}
  }
  .imageContainer {
    padding:5px;
    width:64px;
    height:64px;
    background-color:#edf8f8;
    ${tw`text-center rounded-full`}
    img {
      ${tw`w-full`}
    }
  }
`

const CardLinks = styled.div`
  ${tw`mt-6 flex`}
  .link {
    ${tw`mr-8 last:mr-0 text-gray-400 hocus:text-primary-500 transition duration-300`}
    .icon {
      ${tw`fill-current w-6 h-6`}
    }
  }
`
function getBoxClassNames(index){
  console.log(index);
  const boxImg = "boxImg"+ (index + 1);
  return boxImg + " imageContainer";
}
export default ({
  noCardSpace= true,
  cards = [],
  isAnimate= true
}) => {
  const windowWidth = window.innerWidth;
  console.log(windowWidth);
  return (
    <Cards style={{right:(noCardSpace == true) ? 6+"rem" : '0'} } >
      {cards.map((card, index) => (
        <Card key={index}>
          <CardContent>
            <div className={getBoxClassNames(index)}>
              <img src={card.imageSrc} alt="" />
            </div>
            <span className="name">{card.title}</span>
            <p className="subText">{card.subText}</p>
          </CardContent>
        </Card>
      ))}
      {(windowWidth > 639 && isAnimate == true) && cards.map((card, index) => (
        <Card key={index}>
          <CardContent>
            <div className={getBoxClassNames(index)}>
              <img src={card.imageSrc} alt="" />
            </div>
            <span className="name">{card.title}</span>
            <p className="subText">{card.subText}</p>
          </CardContent>
        </Card>
      ))}
      {(windowWidth > 639 && isAnimate == true) && cards.map((card, index) => (
        <Card key={index}>
          <CardContent>
            <div className={getBoxClassNames(index)}>
              <img src={card.imageSrc} alt="" />
            </div>
            <span className="name">{card.title}</span>
            <p className="subText">{card.subText}</p>
          </CardContent>
        </Card>
      ))}
    </Cards>
  );
};

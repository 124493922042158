import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line

const FormContainer = styled.div`
  ${tw`text-white relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-white focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-white`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col justify-between`;
const Column = tw.div`xs:w-full xs:flex-col xs:m-0 flex sm:flex-row`;
const TextAreaWrapper = tw.div`flex flex-row`;
const InputContainer = tw.div`relative flex flex-col xs:w-full sm:w-1/2 sm:flex-row py-5 mt-6 sm:mr-5`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-brand-100 rounded font-bold tracking-wide text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-brand-700 hocus:-translate-y-px hocus:shadow-xl`;


export default ({
  fromPage = "",
  wappAllow = true,
  formActionUrl = ""

}) => {
  return (
    <FormContainer>
      <div tw="mx-auto max-w-4xl">
        <form action="{formActionUrl}">
          <TwoColumn>
            <Column>
              <InputContainer>
                <Label htmlFor="fname-input">Your Name</Label>
                <Input id="fname-input" type="text" name="fname" placeholder="" />
              </InputContainer>
              <InputContainer>
                <Label htmlFor="lname-input">Last Name</Label>
                <Input id="lname-input" type="text" name="lname" placeholder="E.g. Doe" />
              </InputContainer>
            </Column>
            <Column>
              <InputContainer>
                <Label htmlFor="email-input">Email Address</Label>
                <Input id="email-input" type="email" name="email" placeholder="E.g. john@mail.com" />
              </InputContainer>
              <InputContainer>
                <Label htmlFor="phone-input">Phone</Label>
                <Input id="phone-input" type="phone" name="phone" placeholder="E.g. 9899999999" />
              </InputContainer>
              <Input id="fromPage-input" type="hidden" name="fromPage" value="{fromPage}"/>
              
            </Column>
          </TwoColumn>
          <TwoColumn>
            <TextAreaWrapper>
              <InputContainer tw="flex-1">
                <Label htmlFor="message-input">How Can We Realise Your Digital Goals ?</Label>
                <TextArea id="message-input" name="message" placeholder=""/>
              </InputContainer>
            </TextAreaWrapper>
          </TwoColumn>

          <SubmitButton type="submit" value="Submit">Submit</SubmitButton>
        </form>
      </div>
    </FormContainer>
  );
};

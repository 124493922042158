import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/AurumHeroContent.js";
import Features from "components/features/ThreeColWithTopImage.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
import FAQ from "components/faqs/SingleCol.js";
// import GetStarted from "components/cta/DownloadApp.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
import VideoContentImage from "images/aurum_Icons/aurum-video-library.svg";
import ContentSecurityImage from "images/aurum_Icons/aurum-contentSecurity.svg";
import DevicesImage from "images/aurum_Icons/aurum-devicesImage.svg";
import caseStudyLogoImage from "images/tsilogo.png";
import caseStudyProfileImage from "images/tsi_deepak_tek.png"
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import DesignIllustration from "images/aurum_Icons/heroImg.svg";
import { Helmet } from 'react-helmet';
export default () => {
  const Subheading = tw.p`tracking-widest text-textBlack text-center`;
  const HighlightedText = tw.span`text-primary-500`;
  const VerticalSeprator = tw.div`mt-20 xs:mt-0`;
  const TITLE = 'Aurum Customised Apps';
  const tagNameD = 'Customised Apps for Content Delivery and Brand Identity with UX / CX';

  return (
    <>
      <Helmet
      
      title={TITLE}
      
      meta={[
        {
          name: 'description',
          content: {tagNameD},
        },
        {
          property: `og:title`,
          content: {TITLE},
        },
        {
          property: `og:description`,
          content: {tagNameD},
        }]}
    />
      <Hero roundedHeaderButton={true} 
        mainTitle="Create Apps for Immersive Experiences that Wows" 
        subTitle="Build your Brand Identity, Enhance User Engagement, Boost Your Business with Apps Developed with UX / UI & CX"
        buttonText="Lets Go"
        buttonUrl="https://wa.me/918826622806/?text=Hi, We would like develop our Customised Apps"
        imageSrc={StrongBrandImage}
        showClient= {false}
      />
      <AnimationRevealPage>
       
        <VerticalSeprator className="v-seperator" />
        <MainFeature
          primaryButtonText=""
          primaryButtonUrl = ""
          heading="Build Your Brand Identity"
          description="Aurum customised Apps allow you to establish a significant Brand Identity by improvising the entire experience from login to sale & service of products. It helps you drive maximum value at every touch point, with a very high brand retention.<p>1. Build Your Apps with Your Brand Colors, Logos and Splash Screens.</p><p>2. Customise the User Registration and Landing Flows to improve user experience.</p><p>3. Design and Use Proper and Appropriate Images through out the App.</p><p>4. Customise the App to Build your business and products as it should be, rather than Customising Your Business as per the available apps.</p><p>5. Make proper mailers for every user interaction, in line with your Branding and Brand Identity.</p>"
          subheading="Own the App, embed the Digital Experience that established your brand, Not Ours."
          textOnLeft={false}
          imageSrc={StrongBrandImage}
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        <MainFeature
          primaryButtonText=""
          primaryButtonUrl = ""
          heading="Engage Your Users Like Never Before with UX / CX"
          description="Unlock a more immersive experience for your users that allows them to more meaningfully connect to Your Brand and Content.<p>1. Offer All Kinds of Contents seamlessly. Organise a Live Event, or offer curated videos, modify the content structure any time, with any type of content.</p><p>2. Spark meaningful conversations among users and engage them through closed social feeds.</p><p>3. make your app more interactive and personalise the user experience through Aurum’s app content scheduling integration and gamification.</p><p>4. Make your live sessions / event more interactive with polls, quizzes, Q&As, and more to drive users engagement and satisfaction.</p>"
          subheading="Achieve 20x User Engagement, increased sales and business growth."
          imageSrc={VideoContentImage}
          imageBorder={false}
          imageDecoratorBlob={true}
        />
        
        <FAQ
          subheading={<Subheading></Subheading>}
          description={'Customised Apps with Brand Identity for All Kinds of Contents'}
          heading={
            <>
              Frequently Asked Questions 
            </>
          }
          faqs={[
            {
              question: "What is the significance of UX / UI ?",
              answer:
                "User experience (UX / UI) is important because it tries to fulfill the user's needs. It aims to provide positive experiences that keep a user loyal to the product or brand. Additionally, a meaningful user experience allows you to define customer journeys on your product that are most conducive to business success and growth."
            },
            {
              question: "What is the significance of CX ?",
              answer:
                "Delivering exceptional customer experiences ensures you high customer loyalty and customer retention. The higher the customer loyalty, the more purchases they will make from you."
            },
            {
              question: "What kind of customisations are possible with Aurum Apps ?",
              answer:
                "A great app needs customised user experience and interface (UX UI) according to the business model of the organisation. An app built for corporate training in a BPO will be entirely different from the app built for corporate training of Finance Staff. Similarly an app built for JEE Coaching will not be suitable for running PG NEET Coaching.<p>Apart from standard dynamic features of Aurum Apps, A few important areas where it will be Customised are - <ul><li>Everything Related to Brand Identity - Brand Colors, Logos, Splash Screens, Messages, Banners etc.</li><li>User Registration Flow</li><li>App Dashboards and its Contents</li><li>Content Delivery Frameworks</li><li>Product Listing and Activations</li></ul></p>"
            },
            {
              question: "What are the different kinds of contents that can be delivered using Aurum Apps ?",
              answer:
                "Aurum Apps Supported Content Formats - Live Streaming, ZOOM Integrations, Recorded Videos in MP4 / HLS Format, PDFs, interactive eBooks, MCQ & FIB Assessments."
            },
            {
              question: "Which devices can be used with Aurum Apps ?",
              answer:
                "Aurum Apps are developed for Android & iOS mobiles and tablets, MAC and Windows Desktops / Laptops."
            },
            {
              question: "How strong is the content security with Aurum Apps ?",
              answer:
                "Successfully delivering live and on-demand video content starts with choosing a secure streaming platform. Unauthorized access can compromise the revenue-boosting strategies of subscription and pay-per-view services. And when a streaming implementation involves sensitive or private data, protection is key.<p>Aurum offers <b>content protection for every type of content deliverd</b> - which includes encryption for Live Streams, Recorded Videos, PDFs and Prevents Screen Capture of Almost All Mobiles, Desktops and Laptops.</p>"
            }
          ]}
        />
        <PreFooter fromPage = "Customised Apps" formheading = "Building Apps with Brand Identity Amplifies User Engagement & Conversion" formsubheading = "Book Our Free Discussion Session Today - <b>Significance of Brand Identity and User Engagement in Online Business.</b>"/>
      </AnimationRevealPage>
      <Footer />
   </>
  );
}

import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "../../images/logo.svg";
import { components } from "ComponentRenderer.js";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";

const Container = tw.div`relative bg-white -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(Column)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold text-black`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent text-black hocus:text-brand-500 hocus:border-brand-500 pb-1 transition duration-300`;
const LinkB = tw.a`border-b-2 border-transparent text-black hocus:text-blackStep-100 hocus:border-blackStep-100 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-1/2`;
const LogoText = tw.h5`ml-2 text-xl font-black text-primary-500`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

const SocialLinksContainer = tw.div`mt-4 `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default ({
  landingPages = components.landingPages,
  innerPages = components.innerPages,
}) => {

  return (
    <Container>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            <LogoImg src={LogoImage} className="aurum-logo" />
          </LogoContainer>
          <CompanyDescription>
            Streamline, Modernise & Customise Your Digital Experiences. Let your digital goals turn into reality.

          </CompanyDescription>
          <SocialLinksContainer>
            <SocialLink href="https://facebook.com">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer>
          
          <CompanyDescription>
          <LinkB href="#">Legal Policy Center</LinkB>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<LinkB href="#">Status</LinkB>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<LinkB href="#">Sitemap</LinkB>
          </CompanyDescription>
        </WideColumn>
        <Column>
          <ColumnHeading>Products</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href={innerPages.CustomisedApps.url}>Aurum Apps</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={innerPages.AboutUsPage.url}>Aurum Offline Platform</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={innerPages.VideoStreaming.url}>Content Delivery APIs</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={innerPages.AboutUsPage.url}>Content Delivery Platform</Link>
            </LinkListItem>

          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Solutions</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="#">Live Video Streaming</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">Live Events</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">Conference Broadcast</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">Recorded Video Streaming</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">Customised Apps</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">Dynamic Content Platform</Link>
            </LinkListItem>

          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Company</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="#">About Us</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">Blogs</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">Why Aurum</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">Careers</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">Lets Collaborate</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">Contact Us</Link>
            </LinkListItem>
           
          </LinkList>
        </Column>
      </FiveColumns>
    </Container>
  );
};

import React from "react";
import tw from "twin.macro";

const Container = tw.div`text-center`;
const Heading = tw.h2`text-black`;

export default ({
 	
  	heading = "Trusted By Leading Institutions in ",
  	textArray = [
	    {
	      textTitle: "Academics",
	    },
	     {
	      textTitle: "Corporates",
	    },
	     {
	      textTitle: "Social Work",
	    },
	    {
	      textTitle: "Hospitals",
	    },
	    {
	      textTitle: "Languages",
	    }
	  ]
	}) => {
  return (
    <Container>
        <Heading className="extraBold rotate-wrapper">
        	<span className='rw-opener'>{heading}</span>
        	<ul className="rw-wrapper">
        		{textArray.map((item, index) => (
        			<li className="rotating-word">{item.textTitle}</li>
        		))}	
        	</ul>
       	</Heading>
    </Container>
  );
};
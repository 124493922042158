// import Cookies from 'universal-cookie';
//import {environment} from '../environment.js';
//import { authHeader } from '../_helpers';
//import {Md5} from 'ts-md5/dist/md5';
// const cookies = new Cookies();
const apiURL = 'https://aurumlabs.co/olv/mobapp/';
export const serverUtilities = {
    login
}; 

// function getCurrentTime(){
//   return Math.round(new Date().getTime()/1000);
// }

function login(mode, username, password) {
     
    let URI = apiURL+"admin/adminWebL/32423423";
    
    let jsonData = JSON.stringify({ 
        signin_mode:mode,
        email: username, 
        phone: '',
        password: password,
        model:'0',
        fcmId:'',
        accessFrom:'BROW',
        main_group: "120990",
        timeExp: '20',
        time: '',
        hash: '',
    })
    let body = jsonData;
    console.log(body);
    const requestOptions = {
        method: 'POST',
        headers: {},
        body: body
    };
    return fetch(URI, requestOptions)
        .then(handleResponse)
        .then(user => {
            console.log(user);
            return user;
        });
}
 function logout() {
    // remove user from local storage to log user out
    //localStorage.removeItem(environment.LocalstorageKey);
   }
  function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}